<template>
  <div class=" mt-2">
    <b-card
      class="mb-0"
      :title="$t('account_creation.titles.account_creation')"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <hr />
        <div class="m-1">
          <b-row>
            <b-col cols="12" xl="12" md="12" sm="12" class="d-flex">
              <b-col cols="6" xl="1" lg="1" md="1" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-info"
                  size="sm"
                  class="top-btns"
                  @click="getAllEntries"
                >
                  <feather-icon
                    icon="BoldIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("account_creation.buttons.bring_all")
                  }}</span>
                </b-button>
              </b-col>
              <b-col v-if="$Can('search_account')" cols="6" xl="2" lg="2" md="2" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  size="sm"
                  class="top-btns"
                  @click="searchAccountModal"
                >
                  <b-icon
                    icon="person-lines-fill"
                    font-scale="2"
                    style="margin-bottom: 0.3rem;"
                  >
                  </b-icon>
                  <span class="text-nowrap">{{
                    $t("account_creation.buttons.search_accounts")
                  }}</span>
                </b-button>
              </b-col>
              <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  size="sm"
                  class="top-btns"
                  @click="verifiedEntries"
                >
                  <b-icon
                    icon="patch-check"
                    font-scale="2"
                    style="margin-bottom: 0.3rem;"
                  >
                  </b-icon>
                  <span class="text-nowrap">{{
                    $t("account_creation.buttons.bring_verified_entries")
                  }}</span>
                </b-button>
              </b-col>
              <b-col cols="6" xl="2" lg="2" md="2" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-secondary"
                  size="sm"
                  class="top-btns"
                  @click="unverifiedEntries"
                >
                  <b-icon
                    icon="patch-question"
                    font-scale="2"
                    style="margin-bottom: 0.3rem;"
                  >
                  </b-icon>
                  <span class="text-nowrap">{{
                    $t("account_creation.buttons.bring_unverified_entries")
                  }}</span>
                </b-button>
              </b-col>
              <b-col cols="6" xl="2" lg="2" md="2" sm="12" class="p-0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-secondary"
                  size="sm"
                  class="top-btns"
                  @click="bringAllAccountCodes()"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("account_creation.buttons.bring_all_account_codes")
                  }}</span>
                </b-button>
              </b-col>
              <b-col v-if="$Can('add_account')" cols="6" xl="2" lg="2" md="2" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="gradient-success"
                  size="sm"
                  class="top-btns"
                  @click="createAccountModal"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("account_creation.buttons.new_entry")
                  }}</span>
                </b-button>
              </b-col>

              <b-col cols="6" xl="1" lg="1" md="1" sm="12">
                <b-button
                  id="info-legend-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="">{{
                    $t("account_creation.buttons.legend_table")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-legend-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("account_creation.titles.legend") }}
                  </template>
                  <b-table-simple hover small caption-top responsive bordered>
                    <b-tbody>
                      <!-- <b-tr>
                        <td style="width: 60%;">
                          {{ $t("account_creation.titles.verified") }}
                        </td>
                        <td style="background-color: springgreen;"></td>
                      </b-tr> -->
                      <b-tr>
                        <td>
                          {{ $t("account_creation.titles.amended") }}
                        </td>
                        <td style="background-color: #ffff99;"></td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-popover>
              </b-col>
            </b-col>
          </b-row>
        </div>
        <div @contextmenu.prevent="$refs.contextMenu.open">
        <b-table
          hover
          :bordered="true"
          ref="refAccountCreationTable"
          class="position-relative p-2"
          :items="filteredTrades"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
         
          select-mode="single"
          responsive
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="
            $t('account_creation.messages.no_matching_account_found')
          "
          :current-page="currentPage"
          :per-page="perPage"
          :tbody-tr-class="legendClass"
          caption-top
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortByDesc"
        >

        <!-- @row-clicked="onRowSelected" -->


          <!-- Column: column filter -->
        <template
              slot="top-row"
              slot-scope="{ fields }"
              v-if="showColumnsFilter"
            >
              <td :class="field.key == 'id' ? 'sticky-column' : ''" v-for="field in tableColumns" :key="field.key">
                <v-select
                  v-if="field.key == 'HolderType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optHolderTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'SettlementType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optSettlementTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'status'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optStatuses"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                
                <input
                  v-else-if="field.key == 'id'"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="'ID_column_search_input'"

                  style="max-width: 75px;"
                />
                
                <input
                  v-else
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                />
                <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
              </td>
            </template>

            <!-- Column: check box -->
          <template #cell(id)="data">
            <div class="d-flex justify-content-center align-items-center">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.item.id  }}</strong>
              </span></b
            >
            <div v-if="showMultiplecheckboks" style="margin-left:0.3em">
            <b-form-checkbox
              v-model="data.item.selectedTrade"
              @change="multiSelectRow(data.item.selectedTrade, data.item)"
            >
            </b-form-checkbox>

          </div>

          </div>
          <div>

            <b-button
                  :id="'trade-controls-' + data.item.id"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                  @click="showTradeControlsPopover('trade-controls-' + data.item.id,data.item)"
                  v-if="$Can('see_controls_menu')"
                >
                <i class="fa-solid fa-bars"></i>

                  
                </b-button>
                <b-popover
                  :target="'trade-controls-' + data.item.id"
                  triggers="click"
                  variant="primary"
                >
                  <template #title>
                    {{ $t('equity_bond.titles.trade_controls') }}
                  </template>

                  <b-button
                  v-if="$Can('verify_account') && seeVerifyButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-success"
                    size="sm"
                    class="top-btns mt-1"
                    @click="verifySelectedAccount(data.item)"
                  >
                    
                    <span class="trades-font"><feather-icon
                      icon="CheckSquareIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("account_creation.buttons.verify_selected_account")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('amend_account')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-warning"
                    size="sm"
                    class="top-btns mt-1"
                    @click="amendEntry(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EditIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("account_creation.buttons.amend_entry")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('view_account')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="viewAccountModal(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EyeIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("account_creation.buttons.view_account")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('create_code') && seeCreateNewAccountButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="createCodeSelectedAccount(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="ClipboardIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("account_creation.buttons.create_new_account_code")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('view_codes') && seeBringAccountButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="bringCodesSelectedAccount(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="ListIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("account_creation.buttons.bring_account_codes")
                    }}</span>
                  </b-button>
                  
                </b-popover>

          </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              v-if="data.value != true"
              pill
              :variant="`light-success`"
              class="text-capitalize"
              style="background-color:rgba(255, 255, 255, 0.5)"
            >
              {{ $t("account_creation.text.verified") }}
            </b-badge>

            <b-badge
              v-else
              pill
              :variant="`light-danger`"
              class="text-capitalize"
              style="background-color:rgba(255, 255, 255, 0.5)"
            >
              {{ $t("account_creation.text.not_verified") }}
            </b-badge>
          </template>

          <!-- Column: username -->
          <template #cell(username)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.username ? data.item.username : "-"
                }}</strong>
              </span></b
            >
          </template>

          <template #cell(CreationDate)="data">
            <span>{{ dateFormat(data.value) }}</span>
          </template>

          <template #cell(VerificationDate)="data">
            <span>{{ dateFormat(data.value) }}</span>
          </template>

          <template #cell(CreationTime)="data">
            <span>{{ data.value }}</span>
          </template>

          <template #cell(VerificationTime)="data">
            <span>{{ data.value }}</span>
          </template>

          <template #cell(VerifiedBy)="data">
            <span>{{ data.value ? data.value : "-" }}</span>
          </template>



          <!-- account controls section -->
          <!-- <template #row-details="row">
            <b-card :title="$t('account_creation.titles.account_controls')">
              <b-col cols="12">
                <hr />
              </b-col>
              <div
                class="d-flex mb-1"
                style="max-width:1300px!important;overflow-x: auto;"
              >
                <b-col v-if="$Can('verify_account')" cols="2" md="2" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-success"
                    size="sm"
                    class="top-btns"
                    @click="verifySelectedAccount(row.item)"
                  >
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("account_creation.buttons.verify_selected_account")
                    }}</span>
                  </b-button>
                </b-col>
                <div
                  class="vl"
                  style="border-left: 1px solid #ebe9f1;height: 100px;"
                ></div>
                <b-col v-if="$Can('amend_account')" cols="2" md="2" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-warning"
                    size="sm"
                    class="top-btns"
                    @click="amendEntry(row.item)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("account_creation.buttons.amend_entry")
                    }}</span>
                  </b-button>
                </b-col>
                <div
                  class="vl"
                  style="border-left: 1px solid #ebe9f1;height: 100px;"
                ></div>
                <b-col v-if="$Can('view_account')" cols="2" md="2" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    size="sm"
                    class="top-btns"
                    @click="viewAccountModal(row.item)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("account_creation.buttons.view_account")
                    }}</span>
                  </b-button>
                </b-col>
                <div
                  class="vl"
                  style="border-left: 1px solid #ebe9f1;height: 100px;"
                ></div>
                <b-col v-if="$Can('create_code')" cols="2" md="2" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="top-btns"
                    @click="createCodeSelectedAccount(row.item)"
                  >
                    <feather-icon
                      icon="ClipboardIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("account_creation.buttons.create_new_account_code")
                    }}</span>
                  </b-button>
                </b-col>
                <div
                  class="vl"
                  style="border-left: 1px solid #ebe9f1;height: 100px;"
                ></div>
                <b-col v-if="$Can('view_codes')" cols="2" md="2" sm="12" class="p-0">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    size="sm"
                    class="top-btns"
                    @click="bringCodesSelectedAccount(row.item)"
                  >
                    <feather-icon
                      icon="ListIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("account_creation.buttons.bring_account_codes")
                    }}</span>
                  </b-button>
                </b-col>
              </div>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-card>
          </template> -->
        </b-table>
        </div>
        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkboks"
                  : "Hide multiple checkboks"
              }}</span>
            </b-link>
          </li>
          <li
          v-if="selectedMultipleTrades.length > 0"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon
                icon="CopyIcon"
                size="16"
              />
              <span class="ml-75">{{ $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>

        <div class="mx-2 mb-2" v-if="allAccounts.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.multiple_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>
                <template #cell(info)="data">

                  <b-badge
                    v-if="data.item.info == 'Pass'"
                    pill
                    :variant="`light-success`"
                    class="text-capitalize"
                    style="background-color:rgba(255, 255, 255, 0.5)"
                  >
                    {{ data.item.info }}
                  </b-badge>

                  <b-badge
                    v-else
                    pill
                    :variant="`light-danger`"
                    class="text-capitalize"
                    style="background-color:rgba(255, 255, 255, 0.5)"
                  >
                    {{ data.item.info }}
                  </b-badge>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>

    {{ /*account create modal*/ }}
    <b-modal
      id="accountModal"
      scrollable
      v-model="accountCreateModal"
      :title="$t('account_creation.titles.account_management_screen')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.create_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="createAccount"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card>
                    <!-- Account Name-->
                    <b-form-group
                      :label="$t('account_creation.account_form.account_name')"
                      label-for="AccountName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.account_name')"
                        rules="required"
                      >
                        <b-form-input
                          id="AccountName"
                          size="sm"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          @blur="accountNameNumberCheck('create')"
                          v-model="accountData.AccountName"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Number-->
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.account_number')
                      "
                      label-for="AccountNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.account_number')
                        "
                        rules="required|min:10|max:10"
                      >
                        <b-form-input
                          id="AccountNumber"
                          @blur="accountNumberCheck"
                          size="sm"
                          type="number"
                          v-model="accountData.AccountNumber"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Holder Type-->
                    <b-form-group
                      :label="$t('account_creation.account_form.holder_type')"
                      label-for="HolderType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.holder_type')"
                        rules="required"
                      >
                        <v-select
                          v-model="accountData.HolderType"
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                          id="HolderType"
                          class="select-size-sm"
                          :clearable="false"
                          :options="holderTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Setlement Type-->
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.settlement_type')
                      "
                      label-for="SettlementType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.settlement_type')
                        "
                        rules="required"
                      >
                        <v-select
                          v-model="accountData.SettlementType"
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                          id="SettlementType"
                          class="select-size-sm"
                          :clearable="false"
                          :options="settlementTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                      <!-- Tax Residency-->


                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_residency')
                      "
                      label-for="TaxResidency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.tax_residency')
                        "
                        rules="required"
                      >
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          :clearable="false"
                          :options="countryNames"
                          :taggable="true"
                          @option:selected="selectRate"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_rate')
                      "
                      label-for="TaxRate"
                    >
                     
                    <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="selectedRate"
                        :disabled="true"
                      ></b-form-input>
                       
                    </b-form-group>

                  </b-card>
                </b-col>

                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card>
                    <!-- Display Name-->
                    <b-form-group
                      :label="$t('account_creation.account_form.display_name')"
                      label-for="DisplayName"
                    >
                      <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="accountData.DisplayName"
                      ></b-form-input>
                    </b-form-group>

                    <!-- Phone Number-->
                    <b-form-group
                      :label="$t('account_creation.account_form.phone')"
                      label-for="Phone1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.phone')
                        "
                        rules="min:10|max:12"
                      >
                        <b-form-input
                          id="Phone1"
                          size="sm"
                          type="number"
                          v-model="accountData.Phone1"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Phone Number 2 -->
                    <b-form-group
                      :label="$t('account_creation.account_form.phone2')"
                      label-for="Phone2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.phone2')
                        "
                        rules="min:10|max:12"
                      >
                        <b-form-input
                          id="Phone2"
                          size="sm"
                          type="number"
                          v-model="accountData.Phone2"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Contact Email-->

                    <b-form-group
                      :label="$t('account_creation.account_form.contact_email')"
                      label-for="ContactEmail"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.contact_email')
                        "
                        rules="email"
                      >
                        <b-form-input
                          id="ContactEmail"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          v-model="accountData.ContactEmail"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Email 2-->

                    <b-form-group
                      :label="$t('account_creation.account_form.email2')"
                      label-for="Email2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.email2')"
                        rules="email"
                      >
                        <b-form-input
                          id="Email2"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          v-model="accountData.Email2"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Address-->
                    <b-form-group
                      :label="$t('account_creation.account_form.adress')"
                      label-for="Address"
                    >
                      <b-form-textarea
                        rows="3"
                        size="sm"
                        id="Address"
                        type="textarea"
                        v-model="accountData.Address"
                      />
                    </b-form-group>

                    <!-- Client Notes-->
                    <b-form-group
                      :label="$t('account_creation.account_form.client_notes')"
                      label-for="ClientNote"
                    >
                      <b-form-textarea
                        rows="3"
                        size="sm"
                        id="ClientNote"
                        type="textarea"
                        v-model="accountData.ClientNote"
                      />
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("account_creation.overlay_messages.account_creating_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** account create modal*/ }}
    </b-modal>

    {{ /*account search modal*/ }}
    <b-modal
      id="accountModal"
      scrollable
      v-model="accountSearchModal"
      :title="$t('account_creation.titles.search_account')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.search_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="searchAccount"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <b-form>
            <b-row class="mt-2">
              <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                <b-card>
                  <!-- Account Name-->
                  <b-form-group
                    :label="$t('account_creation.account_form.account_name')"
                    label-for="AccountName"
                  >
                    <b-form-input
                      id="AccountName"
                      size="sm"
                      type="text"
                      v-model="accountData.AccountName"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Account Number-->
                  <b-form-group
                    :label="$t('account_creation.account_form.account_number')"
                    label-for="AccountNumber"
                  >
                    <b-form-input
                      id="AccountNumber"
                      size="sm"
                      type="number"
                      v-model="accountData.AccountNumber"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Holder Type-->
                  <b-form-group
                    :label="$t('account_creation.account_form.holder_type')"
                    label-for="HolderType"
                  >
                    <v-select
                      v-model="accountData.HolderType"
                      :label="$t('account_creation.account_form.holder_type')"
                      id="HolderType"
                      class="select-size-sm"
                      clearable
                      :options="holderTypes"
                    />
                  </b-form-group>

                  <!-- Setlement Type-->
                  <b-form-group
                    :label="$t('account_creation.account_form.settlement_type')"
                    label-for="SettlementType"
                  >
                    <v-select
                      v-model="accountData.SettlementType"
                      :label="
                        $t('account_creation.account_form.settlement_type')
                      "
                      id="SettlementType"
                      class="select-size-sm"
                      :clearable="true"
                      :options="settlementTypes"
                    />
                  </b-form-group>



                  <!-- tax residency countries -->

                  <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_residency')
                      "
                      label-for="TaxResidency"
                    >
                 
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          class="select-size-sm"
                          :clearable="false"
                          :options="countryNames"
                          :taggable="true"
                          @option:selected="selectRate"

                        />
                       
                    </b-form-group>

                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_rate')
                      "
                      label-for="TaxRate"
                    >
                     
                    <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="selectedRate"
                        :disabled="true"
                      ></b-form-input>
                       
                    </b-form-group>


               
                </b-card>
              </b-col>

              <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                <b-card>
                  <!-- Display Name-->
                  <b-form-group
                    :label="$t('account_creation.account_form.display_name')"
                    label-for="DisplayName"
                  >
                    <b-form-input
                      id="DisplayName"
                      size="sm"
                      type="text"
                      v-model="accountData.DisplayName"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Phone Number-->
                  <b-form-group
                    :label="$t('account_creation.account_form.phone')"
                    label-for="Phone1"
                  >
                    <b-form-input
                      id="Phone1"
                      size="sm"
                      type="tel"
                      v-model="accountData.Phone1"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Phone Number 2 -->
                  <b-form-group
                    :label="$t('account_creation.account_form.phone2')"
                    label-for="Phone2"
                  >
                    <b-form-input
                      id="Phone2"
                      size="sm"
                      type="tel"
                      v-model="accountData.Phone2"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Contact Email-->

                  <b-form-group
                    :label="$t('account_creation.account_form.contact_email')"
                    label-for="ContactEmail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('account_creation.account_form.contact_email')"
                      rules="email"
                    >
                      <b-form-input
                        id="ContactEmail"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        v-model="accountData.ContactEmail"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Email 2-->

                  <b-form-group
                    :label="$t('account_creation.account_form.email2')"
                    label-for="Email2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('account_creation.account_form.email2')"
                      rules="email"
                    >
                      <b-form-input
                        id="Email2"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        v-model="accountData.Email2"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Address-->
                  <b-form-group
                    :label="$t('account_creation.account_form.adress')"
                    label-for="Address"
                  >
                    <b-form-textarea
                      rows="3"
                      size="sm"
                      id="Address"
                      type="textarea"
                      v-model="accountData.Address"
                    />
                  </b-form-group>

                  <!-- Client Notes-->
                  <b-form-group
                    :label="$t('account_creation.account_form.client_notes')"
                    label-for="ClientNote"
                  >
                    <b-form-textarea
                      rows="3"
                      size="sm"
                      id="ClientNote"
                      type="textarea"
                      v-model="accountData.ClientNote"
                    />
                  </b-form-group>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t("account_creation.overlay_messages.account_checking_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** account search modal*/ }}
    </b-modal>

    {{ /*account view modal*/ }}
    <b-modal
      id="accountModal"
      scrollable
      v-model="accountViewModal"
      :title="$t('account_creation.titles.account_viewing_form')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.view_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <b-form>
            <b-row class="mt-2">
              <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                <b-card>
                  <!-- Account Name-->
                  <b-form-group
                    :label="$t('account_creation.account_form.account_name')"
                    label-for="AccountName"
                  >
                    <b-form-input
                      id="AccountName"
                      size="sm"
                      type="text"
                      v-model="accountData.AccountName"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Account Number-->
                  <b-form-group
                    :label="$t('account_creation.account_form.account_number')"
                    label-for="AccountNumber"
                  >
                    <b-form-input
                      id="AccountNumber"
                      size="sm"
                      type="number"
                      v-model="accountData.AccountNumber"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Holder Type-->
                  <b-form-group
                    :label="$t('account_creation.account_form.holder_type')"
                    label-for="HolderType"
                  >
                    <v-select
                      v-model="accountData.HolderType"
                      :label="$t('account_creation.account_form.holder_type')"
                      id="HolderType"
                      class="select-size-sm"
                      :clearable="false"
                      :options="holderTypes"
                      :disabled="true"
                    />
                  </b-form-group>

                  <!-- Setlement Type-->
                  <b-form-group
                    :label="$t('account_creation.account_form.settlement_type')"
                    label-for="SettlementType"
                  >
                    <v-select
                      v-model="accountData.SettlementType"
                      :label="
                        $t('account_creation.account_form.settlement_type')
                      "
                      id="SettlementType"
                      class="select-size-sm"
                      :clearable="false"
                      :options="settlementTypes"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-card>

                <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_residency')
                      "
                      label-for="TaxResidency"
                    >
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          class="select-size-sm"
                          :clearable="false"
                          :options="countryNames"
                          :disabled="true"
                          @option:selected="selectRate"
                          

                        />
                      
                    </b-form-group>
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_rate')
                      "
                      label-for="TaxRate"
                    >
                     
                    <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="selectedRate"
                        :disabled="true"
                      ></b-form-input>
                       
                    </b-form-group>

                <b-card
                  v-if="accountData.History && accountData.History.CreatedBy"
                >
                  <small
                    ><span class="text-info"
                      >{{ $t("account_creation.text.created_by") }} </span
                    >{{ accountData.History.CreatedBy }}
                    {{ $t("account_creation.text.on") }}
                    {{ dateFormat(accountData.History.CreationDate) }} -
                    {{ accountData.History.CreationTime }}</small
                  >
                </b-card>

                <b-card
                  v-if="accountData.History && accountData.History.AmendedBy"
                >
                  <small
                    ><span class="text-warning"
                      >{{ $t("account_creation.text.amended_by") }}
                    </span>
                    {{ accountData.History.AmendedBy }}
                    {{ $t("account_creation.text.on") }}
                    {{ dateFormat(accountData.History.AmendmentDate) }} -
                    {{ accountData.History.AmendmentTime }}</small
                  >
                  <br
                    v-if="
                      accountData.History &&
                        accountData.History.AmendmentReason != null
                    "
                  />
                  <hr style="margin-bottom: 0.5rem;" v-if="
                        accountData.History &&
                          accountData.History.AmendmentReason != null
                      ">
                  <small
                    v-if="
                      accountData.History &&
                        accountData.History.AmendmentReason != null
                    "
                    ><span class="text-danger">{{
                      $t("account_creation.text.reason")
                    }}</span>
                    {{ accountData.History.AmendmentReason }}
                  </small>
                </b-card>

                <b-card
                  v-if="accountData.History && accountData.History.VerifiedBy"
                >
                  <small
                    ><span class="text-success">{{
                      $t("account_creation.text.verified_by")
                    }}</span>
                    {{ accountData.History.VerifiedBy }}
                    {{ $t("account_creation.text.on") }}
                    {{ dateFormat(accountData.History.VerificationDate) }} -
                    {{
                      accountData.History.VerificationTime
                    }}</small
                  >
                </b-card>
              </b-col>

              <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                <b-card>
                  <!-- Display Name-->
                  <b-form-group
                    :label="$t('account_creation.account_form.display_name')"
                    label-for="DisplayName"
                  >
                    <b-form-input
                      id="DisplayName"
                      size="sm"
                      type="text"
                      v-model="accountData.DisplayName"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Phone Number-->
                  <b-form-group
                    :label="$t('account_creation.account_form.phone')"
                    label-for="Phone1"
                  >
                    <b-form-input
                      id="Phone1"
                      size="sm"
                      type="tel"
                      v-model="accountData.Phone1"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Phone Number 2 -->
                  <b-form-group
                    :label="$t('account_creation.account_form.phone2')"
                    label-for="Phone2"
                  >
                    <b-form-input
                      id="Phone2"
                      size="sm"
                      type="tel"
                      v-model="accountData.Phone2"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Contact Email-->

                  <b-form-group
                    :label="$t('account_creation.account_form.contact_email')"
                    label-for="ContactEmail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('account_creation.account_form.contact_email')"
                      rules="email"
                    >
                      <b-form-input
                        id="ContactEmail"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        v-model="accountData.ContactEmail"
                        :disabled="true"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Email 2-->

                  <b-form-group
                    :label="$t('account_creation.account_form.email2')"
                    label-for="Email2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('account_creation.account_form.email2')"
                      rules="email"
                    >
                      <b-form-input
                        id="Email2"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        v-model="accountData.Email2"
                        :disabled="true"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Address-->
                  <b-form-group
                    :label="$t('account_creation.account_form.adress')"
                    label-for="Address"
                  >
                    <b-form-textarea
                      rows="3"
                      size="sm"
                      id="Address"
                      type="textarea"
                      v-model="accountData.Address"
                      :disabled="true"
                    />
                  </b-form-group>

                  <!-- Client Notes-->
                  <b-form-group
                    :label="$t('account_creation.account_form.client_notes')"
                    label-for="ClientNote"
                  >
                    <b-form-textarea
                      rows="3"
                      size="sm"
                      id="ClientNote"
                      type="textarea"
                      v-model="accountData.ClientNote"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("account_creation.overlay_messages.account_vieving_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** view modal*/ }}
    </b-modal>

    {{ /*account amend modal*/ }}
    <b-modal
      :no-enforce-focus="true"
      id="accountModal"
      scrollable
      v-model="accountAmendModal"
      :title="$t('account_creation.titles.account_amendment_form')"
      @hidden="resetAccountModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      no-close-on-backdrop
      
      :ok-title="$t('account_creation.modal.amend_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="amendAccount"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="amendValidation">
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card>
                    <!-- Account Name-->
                    <b-form-group
                      :label="$t('account_creation.account_form.account_name')"
                      label-for="AccountName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.account_name')"
                        rules="required"
                      >
                        <b-form-input
                          id="AccountName"
                          size="sm"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          @change="accountNameNumberCheck('amend')"
                          v-model="accountData.AccountName"
                          :disabled="accountData.History.VerifiedBy != null"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Number-->
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.account_number')
                      "
                      label-for="AccountNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.account_number')
                        "
                        rules="required|min:10|max:10"
                      >
                        <b-form-input
                          id="AccountNumber"
                          @blur="accountNumberCheck"
                          size="sm"
                          type="number"
                          v-model="accountData.AccountNumber"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Holder Type-->
                    <b-form-group
                      :label="$t('account_creation.account_form.holder_type')"
                      label-for="HolderType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.holder_type')"
                        rules="required"
                      >
                        <v-select
                          v-model="accountData.HolderType"
                          :label="
                            $t('account_creation.account_form.holder_type')
                          "
                          id="HolderType"
                          class="select-size-sm"
                          :clearable="false"
                          :options="holderTypes"
                          :disabled="accountData.History.VerifiedBy != null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Setlement Type-->
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.settlement_type')
                      "
                      label-for="SettlementType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.settlement_type')
                        "
                        rules="required"
                      >
                        <v-select
                          v-model="accountData.SettlementType"
                          :label="
                            $t('account_creation.account_form.settlement_type')
                          "
                          id="SettlementType"
                          class="select-size-sm"
                          :clearable="false"
                          :options="settlementTypes"
                          :disabled="accountData.History.VerifiedBy != null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_residency')
                      "
                      label-for="TaxResidency"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.tax_residency')
                        "
                        rules="required"
                      >
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          class="select-size-sm"
                          :clearable="false"
                          :options="countryNames"
                          :taggable="true"
                          @option:selected="selectRate"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_rate')
                      "
                      label-for="TaxRate"
                    >
                     
                    <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="selectedRate"
                        :disabled="true"
                      ></b-form-input>
                       
                    </b-form-group>
                  </b-card>

                  

                  <b-card
                    v-if="accountData.History && accountData.History.CreatedBy"
                  >
                    <small
                      ><span class="text-info">
                        {{ $t("account_creation.text.created_by") }} </span
                      >{{ accountData.History.CreatedBy }}
                      {{ $t("account_creation.text.on") }}
                      {{ dateFormat(accountData.History.CreationDate) }} -
                      {{ accountData.History.CreationTime }}</small
                    >
                  </b-card>

                  <b-card
                    v-if="accountData.History && accountData.History.AmendedBy"
                  >
                    <small
                      ><span class="text-warning">{{
                        $t("account_creation.text.amended_by")
                      }}</span>
                      {{ accountData.History.AmendedBy }}
                      {{ $t("account_creation.text.on") }}
                      {{ dateFormat(accountData.History.AmendmentDate) }} -
                      {{
                        accountData.History.AmendmentTime
                      }}</small
                    >
                    <br
                      v-if="
                        accountData.History &&
                          accountData.History.AmendmentReason != null
                      "
                    />
                    <hr style="margin-bottom: 0.5rem;" v-if="
                        accountData.History &&
                          accountData.History.AmendmentReason != null
                      ">
                    <small
                      v-if="
                        accountData.History &&
                          accountData.History.AmendmentReason != null
                      "
                      ><span class="text-danger">{{
                        $t("account_creation.text.reason")
                      }}</span>
                      {{ accountData.History.AmendmentReason }}
                    </small>
                  </b-card>

                  <b-card
                    v-if="accountData.History && accountData.History.VerifiedBy"
                  >
                    <small
                      ><span class="text-success">{{
                        $t("account_creation.text.verified_by")
                      }}</span>
                      {{ accountData.History.VerifiedBy }}
                      {{ $t("account_creation.text.on") }}
                      {{ dateFormat(accountData.History.VerificationDate) }} -
                      {{
                        accountData.History.VerificationTime
                      }}</small
                    >
                  </b-card>
                </b-col>

                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card>
                    <!-- Display Name-->
                    <b-form-group
                      :label="$t('account_creation.account_form.display_name')"
                      label-for="DisplayName"
                    >
                      <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="accountData.DisplayName"
                      ></b-form-input>
                    </b-form-group>

                     <!-- Phone Number-->
                     <b-form-group
                      :label="$t('account_creation.account_form.phone')"
                      label-for="Phone1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.phone')
                        "
                        rules="min:10|max:12"
                      >
                        <b-form-input
                          id="Phone1"
                          size="sm"
                          type="number"
                          v-model="accountData.Phone1"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Phone Number 2 -->
                    <b-form-group
                      :label="$t('account_creation.account_form.phone2')"
                      label-for="Phone2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.phone2')
                        "
                        rules="min:10|max:12"
                      >
                        <b-form-input
                          id="Phone2"
                          size="sm"
                          type="number"
                          v-model="accountData.Phone2"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Contact Email-->

                    <b-form-group
                      :label="$t('account_creation.account_form.contact_email')"
                      label-for="ContactEmail"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.contact_email')
                        "
                        rules="email"
                      >
                        <b-form-input
                          id="ContactEmail"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          v-model="accountData.ContactEmail"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Email 2-->

                    <b-form-group
                      :label="$t('account_creation.account_form.email2')"
                      label-for="Email2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.email2')"
                        rules="email"
                      >
                        <b-form-input
                          id="Email2"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          v-model="accountData.Email2"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Address-->
                    <b-form-group
                      :label="$t('account_creation.account_form.adress')"
                      label-for="Address"
                    >
                      <b-form-textarea
                        rows="3"
                        size="sm"
                        id="Address"
                        type="textarea"
                        v-model="accountData.Address"
                      />
                    </b-form-group>

                    <!-- Client Notes-->
                    <b-form-group
                      :label="$t('account_creation.account_form.client_notes')"
                      label-for="ClientNote"
                    >
                      <b-form-textarea
                        rows="3"
                        size="sm"
                        id="ClientNote"
                        type="textarea"
                        v-model="accountData.ClientNote"
                      />
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t(
                  "account_creation.overlay_messages.account_amendment_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** amend modal*/ }}
    </b-modal>

    {{ /*account verify modal*/ }}
    <b-modal
      id="accountModal"
      scrollable
      v-model="accountVerifyModal"
      :title="$t('account_creation.titles.verify_account_form')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.verify_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="verifyAccount"
    >
      <b-overlay :show="AccountModalLoading" rounded="sm" no-fade>
        <b-container>
          <b-form>
            <b-row class="mt-2">
              <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                <b-card>
                  <!-- Account Name-->
                  <b-form-group
                    :label="$t('account_creation.account_form.account_name')"
                    label-for="AccountName"
                  >
                    <b-form-input
                      id="AccountName"
                      size="sm"
                      type="text"
                      v-model="accountData.AccountName"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Account Number-->
                  <b-form-group
                    :label="$t('account_creation.account_form.account_number')"
                    label-for="AccountNumber"
                  >
                    <b-form-input
                      id="AccountNumber"
                      size="sm"
                      type="number"
                      v-model="accountData.AccountNumber"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Holder Type-->
                  <b-form-group
                    :label="$t('account_creation.account_form.holder_type')"
                    label-for="HolderType"
                  >
                    <v-select
                      v-model="accountData.HolderType"
                      :label="$t('account_creation.account_form.holder_type')"
                      id="HolderType"
                      class="select-size-sm"
                      :clearable="false"
                      :options="holderTypes"
                      :disabled="true"
                    />
                  </b-form-group>

                  <!-- Setlement Type-->
                  <b-form-group
                    :label="$t('account_creation.account_form.settlement_type')"
                    label-for="SettlementType"
                  >
                    <v-select
                      v-model="accountData.SettlementType"
                      :label="
                        $t('account_creation.account_form.settlement_type')
                      "
                      id="SettlementType"
                      class="select-size-sm"
                      :clearable="false"
                      :options="settlementTypes"
                      :disabled="true"
                    />
                  </b-form-group>

                  
                  <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_residency')
                      "
                      label-for="TaxResidency"
                    >         
                        <v-select
                          v-model="accountData.TaxResidency"
                          :label="
                            $t('account_creation.account_form.tax_residency')
                          "
                          id="TaxResidency"
                          class="select-size-sm"
                          :clearable="false"
                          :options="countryNames"
                          :taggable="true"
                          @option:selected="selectRate"

                        />
                
                    </b-form-group>
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.tax_rate')
                      "
                      label-for="TaxRate"
                    >
                     
                    <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="selectedRate"
                        :disabled="true"
                      ></b-form-input>
                       
                    </b-form-group>

                </b-card>

                <b-card
                  v-if="accountData.History && accountData.History.CreatedBy"
                >
                  <small
                    ><span class="text-info">
                      {{ $t("account_creation.text.created_by") }} </span
                    >{{ accountData.History.CreatedBy }}
                    {{ $t("account_creation.text.on") }}
                    {{ dateFormat(accountData.History.CreationDate) }} -
                    {{ accountData.History.CreationTime }}</small
                  >
                </b-card>

                <b-card
                  v-if="accountData.History && accountData.History.AmendedBy"
                >
                  <small
                    ><span class="text-warning">{{
                      $t("account_creation.text.amended_by")
                    }}</span>
                    {{ accountData.History.AmendedBy }}
                    {{ $t("account_creation.text.on") }}
                    {{ dateFormat(accountData.History.AmendmentDate) }} -
                    {{ accountData.History.AmendmentTime }}</small
                  >
                  <br
                    v-if="
                      accountData.History &&
                        accountData.History.AmendmentReason != null
                    "
                  />
                  <hr style="margin-bottom: 0.5rem;" v-if="
                        accountData.History &&
                          accountData.History.AmendmentReason != null
                      ">
                  <small
                    v-if="
                      accountData.History &&
                        accountData.History.AmendmentReason != null
                    "
                    ><span class="text-danger">{{
                      $t("account_creation.text.reason")
                    }}</span>
                    {{ accountData.History.AmendmentReason }}
                  </small>
                </b-card>

                <b-card
                  v-if="accountData.History && accountData.History.VerifiedBy"
                >
                  <small
                    ><span class="text-success">{{
                      $t("account_creation.text.verified_by")
                    }}</span>
                    {{ accountData.History.VerifiedBy }}
                    {{ $t("account_creation.text.on") }}
                    {{ dateFormat(accountData.History.VerificationDate) }} -
                    {{
                      accountData.History.VerificationTime
                    }}</small
                  >
                </b-card>
              </b-col>

              <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                <b-card>
                  <!-- Display Name-->
                  <b-form-group
                    :label="$t('account_creation.account_form.display_name')"
                    label-for="DisplayName"
                  >
                    <b-form-input
                      id="DisplayName"
                      size="sm"
                      type="text"
                      v-model="accountData.DisplayName"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Phone Number-->
                  <b-form-group
                    :label="$t('account_creation.account_form.phone')"
                    label-for="Phone1"
                  >
                    <b-form-input
                      id="Phone1"
                      size="sm"
                      type="tel"
                      v-model="accountData.Phone1"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Phone Number 2 -->
                  <b-form-group
                    :label="$t('account_creation.account_form.phone2')"
                    label-for="Phone2"
                  >
                    <b-form-input
                      id="Phone2"
                      size="sm"
                      type="tel"
                      v-model="accountData.Phone2"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>

                  <!-- Contact Email-->

                  <b-form-group
                    :label="$t('account_creation.account_form.contact_email')"
                    label-for="ContactEmail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('account_creation.account_form.contact_email')"
                      rules="email"
                    >
                      <b-form-input
                        id="ContactEmail"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        v-model="accountData.ContactEmail"
                        :disabled="true"
                      ></b-form-input>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Email 2-->

                  <b-form-group
                    :label="$t('account_creation.account_form.email2')"
                    label-for="Email2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('account_creation.account_form.email2')"
                      rules="email"
                    >
                      <b-form-input
                        id="Email2"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        v-model="accountData.Email2"
                        :disabled="true"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Address-->
                  <b-form-group
                    :label="$t('account_creation.account_form.adress')"
                    label-for="Address"
                  >
                    <b-form-textarea
                      rows="3"
                      size="sm"
                      id="Address"
                      type="textarea"
                      v-model="accountData.Address"
                      :disabled="true"
                    />
                  </b-form-group>

                  <!-- Client Notes-->
                  <b-form-group
                    :label="$t('account_creation.account_form.client_notes')"
                    label-for="ClientNote"
                  >
                    <b-form-textarea
                      rows="3"
                      size="sm"
                      id="ClientNote"
                      type="textarea"
                      v-model="accountData.ClientNote"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </b-container>

        <template #overlay v-if="AccountModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t(
                  "account_creation.overlay_messages.account_verifying_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** verify modal*/ }}
    </b-modal>

    {{ /*create code modal*/ }}
    <b-modal
      id="accountModal"
      scrollable
      v-model="createCodeModal"
      :title="$t('account_creation.titles.create_new_code')"
      @hidden="resetAccountModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('account_creation.modal.create_code_modal_ok')"
      :cancel-title="$t('account_creation.modal.modal_cancel')"
      @ok="createNewAccountCode"
    >
      <b-overlay :show="createCodeModalLoading" rounded="sm" no-fade>
        <validation-observer ref="createCodeValidation">
          <b-container>
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card>
                    <!-- Account Name-->
                    <b-form-group
                      :label="$t('account_creation.account_form.account_name')"
                      label-for="AccountName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.account_name')"
                        rules="required"
                      >
                        <b-form-input
                          id="AccountName"
                          size="sm"
                          type="text"
                          v-model="createCodeAccountData.AccountName"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Number-->
                    <b-form-group
                      :label="
                        $t('account_creation.account_form.account_number')
                      "
                      label-for="AccountNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.account_number')
                        "
                        rules="required"
                      >
                        <b-form-input
                          id="AccountNumber"
                          size="sm"
                          type="number"
                          v-model="createCodeAccountData.AccountNumber"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Ccy-->
                    <b-form-group
                      :label="$t('account_creation.account_form.account_ccy')"
                      label-for="AccountCcy"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.account_ccy')"
                        rules="required"
                      >
                        <v-select
                          v-model="createCodeAccountData.AccountCcy"
                          id="AccountCcy"
                          class="select-size-sm"
                          :clearable="false"
                          :options="optAccountCcy"
                          :reduce="(val) => val.Currency"
                          label="Currency"
                          placeholder="Account Currency"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Type-->
                    <b-form-group
                      :label="$t('account_creation.account_form.account_type')"
                      label-for="AccountType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.account_type')"
                        rules="required"
                      >
                        <v-select
                          v-model="createCodeAccountData.AccountType"
                          :placeholder="
                            $t('account_creation.account_form.account_type')
                          "
                          id="AccountType"
                          class="select-size-sm"
                          :clearable="false"
                          :options="optAccountTypes"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Account Code-->
                    <b-form-group
                      :label="$t('account_creation.account_form.account_type')"
                      label-for="AccountCode"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('account_creation.account_form.account_type')"
                        rules="required"
                      >
                        <b-form-input
                          id="AccountCode"
                          size="sm"
                          v-model="createCodeAccountData.AccountCode"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-card>
                </b-col>

                <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                  <b-card>
                    <!-- Display Name-->
                    <b-form-group
                      :label="$t('account_creation.account_form.display_name')"
                      label-for="DisplayName"
                    >
                      <b-form-input
                        id="DisplayName"
                        size="sm"
                        type="text"
                        v-model="createCodeAccountData.DisplayName"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>

                    <!-- Phone Number-->
                    <b-form-group
                      :label="$t('account_creation.account_form.phone')"
                      label-for="Phone1"
                    >
                      <b-form-input
                        id="Phone1"
                        size="sm"
                        type="tel"
                        v-model="createCodeAccountData.Phone1"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>

                    <!-- Phone Number 2 -->
                    <b-form-group
                      :label="$t('account_creation.account_form.phone2')"
                      label-for="Phone2"
                    >
                      <b-form-input
                        id="Phone2"
                        size="sm"
                        type="tel"
                        v-model="createCodeAccountData.Phone2"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>

                    <!-- Contact Email-->

                    <b-form-group
                      :label="$t('account_creation.account_form.contact_email')"
                      label-for="ContactEmail"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('account_creation.account_form.contact_email')
                        "
                      >
                        <b-form-input
                          id="ContactEmail"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          v-model="createCodeAccountData.ContactEmail"
                          :disabled="true"
                        ></b-form-input>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Email 2-->

                    <b-form-group
                      :label="$t('account_creation.account_form.email2')"
                      label-for="Email2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="E-mail 2"
                      >
                        <b-form-input
                          id="Email2"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                          type="email"
                          v-model="createCodeAccountData.Email2"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- Address-->
                    <b-form-group
                      :label="$t('account_creation.account_form.adress')"
                      label-for="Address"
                    >
                      <b-form-textarea
                        rows="3"
                        size="sm"
                        id="Address"
                        type="textarea"
                        v-model="createCodeAccountData.Address"
                        :disabled="true"
                      />
                    </b-form-group>

                    <!-- Client Notes-->
                    <b-form-group
                      :label="$t('account_creation.account_form.client_notes')"
                      label-for="ClientNote"
                    >
                      <b-form-textarea
                        rows="3"
                        size="sm"
                        id="ClientNote"
                        type="textarea"
                        v-model="createCodeAccountData.ClientNote"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
        </validation-observer>
        <template #overlay v-if="createCodeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t(
                  "account_creation.overlay_messages.account_code_creating_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** create code modal*/ }}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import accountStoreModule from "./accountStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import VueContext from "vue-context";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    BTooltip,
    VueContext,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const ACCOUNT_APP_STORE_MODULE_NAME = "account";
    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
      store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME))
        store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      countryNames : null,
      allAccounts: [],
      accountCreateModal: false,
      accountSearchModal: false,
      accountViewModal: false,
      accountAmendModal: false,
      accountVerifyModal: false,
      createCodeModal: false,
      allTeams: [],
      filterCreateModal: null,
      filterCreateModalOn: ["TeamName"],
      updatePermloading: false,
      AccountModalLoading: false,
      createCodeModalLoading: false,
      confrimPassword: null,
      amendedReasonText: null,
      preAccount: null,
      phoneNumberCheck: true,
      accountData: {
        AccountName: null,
        AccountNumber: null,
        HolderType: "INDIVIDUAL",
        SettlementType: "CUSTODIAN",
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        AmendedBy: null,
        AmendmentDate: null,
        AmendmentReason: null,
        AmendmentTime: null,
        ClientNote: null,
        CreatedBy: null,
        CreationDate: moment().format("YYYY-MM-DD"),
        CreationTime: moment().format("HH:mm:ss"),
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
        TaxResidency : 'United Kingdom',
      },
      createCodeAccountData: {
        AccountName: null,
        AccountNumber: null,
        AccountCcy: "CURRENT",
        AccountType: null,
        AccountCode: null,
        HolderType: null,
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        ClientNote: null,
      },
      optAccountCcy: [],
      optAccountTypes: ["CURRENT", "COLLATERAL", "LOAN", "MTM"],
      holderTypes: ["INDIVIDUAL", "CORPORATE"],
      settlementTypes: ["DVP", "CUSTODIAN"],
      filterOn: ["AccountName"],
      sortByDesc: true,
      sortBy: "ID",
      optHolderTypes:[{ title: "all", value: "" },
      { title: "INDIVIDUAL", value: "INDIVIDUAL" },
      { title: "CORPORATE", value: "CORPORATE" },],
      optSettlementTypes:[{ title: "all", value: "" },
      { title: "DVP", value: "DVP" },
      { title: "CUSTODIAN", value: "CUSTODIAN" },],
      optStatuses:[{ title: "all", value: "" },
      { title: "Verified", value: false },
      { title: "Unverified", value: true }],
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "w-0 padding_08 sticky-column",
          thClass: "sticky-column text-center",
          tdClass: "text-center ID_background",
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("account_creation.table.status"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "AccountName",
          label: this.$t("account_creation.table.account_name"),
          sortable: true,
          class: "text-center padding_08",
        },
        {
          key: "AccountNumber",
          label: this.$t("account_creation.table.account_number"),
          sortable: false,
          class: "text-center padding_08",
        },

        {
          key: "HolderType",
          label: this.$t("account_creation.table.holder_type"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "SettlementType",
          label: this.$t("account_creation.table.settlement_type"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "TaxResidency",
          label: this.$t("account_creation.table.tax_residency"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "CreatedBy",
          label: this.$t("account_creation.table.created_by"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "CreationDate",
          label: this.$t("account_creation.table.creation_date"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "CreationTime",
          label: this.$t("account_creation.table.creation_time"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "VerifiedBy",
          label: this.$t("account_creation.table.verified_by"),
          sortable: false,
          class: "text-center padding_08",
        },
        {
          key: "VerificationDate",
          label: this.$t("account_creation.table.verification_date"),
          sortable: false,
          class: "text-center padding_08",
        },

        {
          key: "VerificationTime",
          label: this.$t("account_creation.table.verification_time"),
          sortable: false,
          class: "text-center padding_08",
        },
      //   {
        
      //   key: "TradeActions",
      //   label: "Actions",
      //   class: "text-center",
      //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
      // },
      ],
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.id"),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "AccountNumber",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.accountNumber"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 20%",
        },
        {
          key: "info",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.info"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 20%",
        },
        {
          key: "reason",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.reason"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 40%",
        },

      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [15, 25, 50, 100],
      filter: null,

      loading: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      filters: {
        AccountName: "",
        AccountNumber: "",
        HolderType: "",
      },
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      seeCreateNewAccountButton : false,
      seeVerifyButton : false,
      seeBringAccountButton : false,

      defaultRate:null,
      rates : [],
      selectedRate : null,
    };
  },
  computed: {
    ...mapGetters(["authUser"]),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    filteredTrades() {
      const filtered = this.allAccounts.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0
        ? filtered
        : [
            {
            AccountName: "",
            AccountNumber: "",
            HolderType: "",
          }
          ];
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },

  },
  mounted() {
    this.getAllEntries();
    this.getCountries();

    // this.countryNames = countryNames;

  },
  methods: {
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },

    showTradeControlsPopover(targetTrade,trade){
      if(trade){

          // set default
          this.seeCreateNewAccountButton = false
          this.seeVerifyButton = false
          this.seeBringAccountButton = false


          // set suitable flow buttons

          // see verify button
          if(trade.VerifiedBy ==null && trade.VerificationTime == null && trade.VerificationDate == null){
              this.seeVerifyButton = true
            }

            // see new account buttons
            if(trade.VerifiedBy !=null && trade.VerificationTime != null && trade.VerificationDate != null){
              this.seeCreateNewAccountButton = true
            }

            // see new account buttons
            if(trade.VerifiedBy !=null && trade.VerificationTime != null && trade.VerificationDate != null){
              this.seeBringAccountButton = true
            }
          }

      this.$root.$emit('bv::show::popover', targetTrade)
    },
    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
        
  
        }

      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];
    

        // this.tableColumns[1].class = this.showMultiplecheckboks
        //   ? "text-center"
        //   : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));
      }
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          id: trade.id,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];

      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          VerifiedBy: null,
          VerificationTime: null,
          VerificationDate: null,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          VerifiedBy: null,
          VerificationTime: null,
          VerificationDate: null,
        });
      }


      if (trades.length > 0 && status) {
        if(status == "copyTrades"){
        //this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
        this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },

    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel"
            ? "textarea"
            : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("account/tradeMultipleChangeAccountStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status
            })
            .then((res) => {
              self.loading = false;
              self.multiSelectProcessModalTableData = [];

              if(res.data.successProcesses.length > 0){
                
              self.multiSelectProcessModalTableData.push(...res.data.successProcesses);
              }

              if(res.data.failedProcesses.length > 0){
                
              self.multiSelectProcessModalTableData.push(...res.data.failedProcesses);
              }
              self.showMultiSelectProcessModal = true

              if(res.data.failedProcesses.length > 0){
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage()
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();
                _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));
                self.okMultiSelectProcessModal()
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    // tradeMultipleSelectCopyOpr(tradesCount, trades) {
    //   const self = this;
    //   this.$swal({
    //     title: self.$t("multiCheckBoxTitles.change_copy_title"),
    //     text:
    //       tradesCount +
    //       self.$t("multiCheckBoxTitles.trades_copy_message"),
    //     //icon: "warning",
    //     showCancelButton: true,
    //     showLoaderOnConfirm: true,
    //     inputAttributes: {
    //       style: "margin-top:5px!important",
    //     },
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
    //     cancelButtonText: self.$t("general_buttons.cancel"),
    //     customClass: {
    //       confirmButton: "btn btn-primary mr-1",
    //       cancelButton: "btn btn-danger",
    //       denyButton: "btn btn-warning",
    //       text: "mb-1",
    //     },
    //     showClass: {
    //       popup: "animate__animated animate__fadeInDown",
    //     },
    //     hideClass: {
    //       popup: "animate__animated animate__fadeOutUp",
    //     },
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       self.loading = true;

    //       // set trade data
    //         if (trades.length > 0) {
    //           var setTradesData = [];
    //           trades.forEach((trade) => {
    //             setTradesData.push(self.setTradeData(trade));
    //           });
    //         } 
    //         trades = setTradesData;
    //       store
    //         .dispatch("account/tradeMultipleSelectTransactionCopyOpr", {
    //           auth: self.user,
    //           trades: trades,
    //         })
    //         .then((res) => {
    //           self.loading = false;
    //           self.selectedMultipleTrades = [];

    //           if(res.data.failedCopyOpr.length > 0){
    //             this.multiSelectCopyWarningMessage()
    //             this.okMultiSelectProcessModal()
    //           } else if(res.data.info == 'not_found'){
    //             this.wrongToastMessage(res.data.message)
    //           } else {
    //             self.multiSelectAllTradesCopySuccessMessage();
    //             this.okMultiSelectProcessModal()
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   });
    // },

    okMultiSelectProcessModal(){
      this.showMultiSelectProcessModal = false
      this.multiSelectProcessModalTableData = []
      _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));

      this.getAllEntries();
    },



    //ALL DATAS
    getAllEntries() {
      this.loading = true;

      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("account/refreshPortfolioForClientAccounts", this.user)
            .then((res) => {
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    getCountries() {
      this.loading = true;
      store
        .dispatch("account/getTaxRates")
        .then((res) => {
          this.countryNames = res.data.rates.map((e)=> e.Residency);
          this.rates = res.data.rates;
          this.defaultRate = res.data.defaultRate;
          this.selectRate();
         
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },

    selectRate(){

      const filteredRates = this.rates.filter((e) => e.Residency === this.accountData.TaxResidency);
      this.selectedRate = filteredRates[0].Rate ? filteredRates[0].Rate : this.defaultRate;

    },

    //VERIFIED DATAS
    verifiedEntries() {
      this.loading = true;

      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch(
              "account/refreshPortfolioForClientAccountsVerified",
              this.user
            )
            .then((res) => {
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //UNVERIFIED DATAS
    unverifiedEntries() {
      this.loading = true;
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch(
              "account/refreshPortfolioForClientAccountsNotVerified",
              this.user
            )
            .then((res) => {
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //AMEND BUTTON
    amendEntry(val) {
      this.$checkAccessRight("AccountCreation", "Amend").then((res) => {
        if (res.data.valid) {
          if (val.id != undefined) {
            this.accountData.password = this.$encryptPassword(
              this.accountData.password
            );
            this.AccountModalLoading = true;
            this.loading = true;
            store
              .dispatch("account/selectAccNoFromCustomer", {
                auth: this.user,
                accountNumber: val.AccountNumber,
                ID:val.id
              })
              .then((res) => {
                this.accountData = {
                  ID: res.data.id,
                  AccountName: res.data.AccountName,
                  AccountNumber: res.data.AccountNumber,
                  HolderType: res.data.HolderType,
                  SettlementType: res.data.SettlementType,
                  TaxResidency: res.data.TaxResidency,
                  DisplayName: res.data.DisplayName,
                  Phone1: res.data.Phone1,
                  Phone2: res.data.Phone2,
                  ContactEmail: res.data.ContactEmail,
                  Email2: res.data.Email2,
                  Address: res.data.Address,
                  AmendedBy: res.data.AmendedBy,
                  AmendmentDate: res.data.AmendmentDate,
                  AmendmentReason: res.data.AmendmentReason,
                  AmendmentTime: res.data.AmendmentTime,
                  ClientNote: res.data.ClientNote,
                  CreatedBy: res.data.CreatedBy,
                  CreationDate: res.data.CreationDate,
                  CreationTime: res.data.CreationTime,
                  VerificationDate: res.data.VerificationDate,
                  VerificationTime: res.data.VerificationTime,
                  VerifiedBy: res.data.VerifiedBy,
                  History: res.data.History,
                };
                this.preAccount = JSON.parse(JSON.stringify(this.accountData));
                this.loading = false;
                this.AccountModalLoading = false;
                this.accountAmendModal = true;
                if(this.accountData.History.VerifiedBy != null){
                  this.verfiedAmendMessage()
                }
              })
              .catch((error) => {
                this.AccountModalLoading = false;
                this.loading = false;
                console.log(error);
              });
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //AMEND POPUP OK
    amendAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!_.isEqual(this.preAccount, this.accountData)) {
        this.$checkAccessRight("AccountCreation", "Amend").then((res) => {
          if (res.data.valid) {
            this.$refs.amendValidation.validate().then((success) => {
              if (success) {
                let checkAccountName = 0;
                let checkAccountNumber = 0;

                if (
                  this.preAccount.AccountName != this.accountData.AccountName
                ) {
                  checkAccountName = 1;
                }

                if (
                  this.preAccount.AccountNumber !=
                  this.accountData.AccountNumber
                ) {
                  checkAccountNumber = 1;
                }

                this.AccountModalLoading = true;
                store
                  .dispatch("account/checkAccountDetailsExist", {
                    auth: this.user,
                    accountData: this.accountData,
                    checkAccountName: checkAccountName,
                    checkAccountNumber: checkAccountNumber,
                  })
                  .then((res) => {
                    this.AccountModalLoading = false;
                    if (res.data == "Pass") {
                      this.accountAmendReasonText().then((res) => {
                        if (res) {
                          this.accountData.AmendmentReason = this.amendedReasonText;
                          this.accountData.AmendmentDate = moment().format(
                            "YYYY-MM-DD"
                          );
                          this.accountData.AmendmentTime = moment().format(
                            "HH:mm:ss"
                          );
                          this.accountData.AmendedBy =  this.user.name + ' ' + this.user.surname;
                          store
                            .dispatch("account/updateAmendAccount", {
                              auth: this.user,
                              accountData: this.accountData,
                            })
                            .then((res) => {
                              if (res.data.info == "Pass") {
                                this.AccountModalLoading = false;
                                this.accountAmendModal = false;
                                this.resetAccountModal();
                                this.getAllEntries();
                              } else {
                                this.errorMessage(res.data.message);
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          this.amendOpearionNotDoneMessage();
                        }
                      });
                    } else {
                      this.warningMessage(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.AccountModalLoading = false;
                    this.accountCreateModal = false;
                    this.getAllEntries();
                    console.log(error);
                  });
              } else {
                let notEnteredDatas = "";

                let checkRequiredDatas = _.map(
                  this.$refs.amendValidation.errors,
                  (value, key) => ({ key, value })
                );

                if (checkRequiredDatas.length > 0) {
                  let first = true;
                  checkRequiredDatas.forEach((r) => {
                    if (r.value.length > 0) {
                      if (first) {
                        notEnteredDatas += r.key;
                        first = false;
                      } else {
                        notEnteredDatas += "," + r.key;
                        first = false;
                      }
                    }
                  });
                  this.checkRequiredInputsMessage(notEnteredDatas, 20000);
                }
              }
            });
          } else {
            this.notAuthToastMessage();
          }
        });
      } else {
        this.noChangesMessage();
      }
    },

    async accountAmendReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: this.$t("account_creation.reason.amendment_reason"),
        text: this.$t("account_creation.reason.please_enter_amendment_reason"),
        input: "textarea",
        inputPlaceholder: "Please enter reason for amend.",
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("account_creation.reason.submit"),
        cancelButtonText: this.$t("account_creation.reason.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.amendedReasonText = reason;
              resolve();
            } else {
              resolve(this.$t("account_creation.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },

    //VERIFY BUTTON
    verifySelectedAccount(item) {
      this.$checkAccessRight("AccountCreation", "Verify").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            if (item.AccountNumber != null && item.AccountNumber != "") {
              this.loading = true;
              store
                .dispatch("account/checkVerifyAccount", {
                  auth: this.user,
                  accountNumber: item.AccountNumber,
                })
                .then((res) => {
                  if (res.data.info == "not_verified") {
                    store
                      .dispatch("account/selectAccNoFromCustomer", {
                        auth: this.user,
                        accountNumber: item.AccountNumber,
                        ID:item.id
                      })
                      .then((res) => {
                        this.accountData = {
                          ID: res.data.id,
                          AccountName: res.data.AccountName,
                          AccountNumber: res.data.AccountNumber,
                          HolderType: res.data.HolderType,
                          SettlementType: res.data.SettlementType,
                          TaxResidency: res.data.TaxResidency,
                          DisplayName: res.data.DisplayName,
                          Phone1: res.data.Phone1,
                          Phone2: res.data.Phone2,
                          ContactEmail: res.data.ContactEmail,
                          Email2: res.data.Email2,
                          Address: res.data.Address,
                          AmendedBy: res.data.AmendedBy,
                          AmendmentDate: res.data.AmendmentDate,
                          AmendmentReason: res.data.AmendmentReason,
                          AmendmentTime: res.data.AmendmentTime,
                          ClientNote: res.data.ClientNote,
                          CreatedBy: res.data.CreatedBy,
                          CreationDate: res.data.CreationDate,
                          CreationTime: res.data.CreationTime,
                          VerificationDate: res.data.VerificationDate,
                          VerificationTime: res.data.VerificationTime,
                          VerifiedBy: res.data.VerifiedBy,
                          History: res.data.History,
                        };
                        this.loading = false;
                        this.AccountModalLoading = false;
                        this.accountVerifyModal = true;
                      })
                      .catch((error) => {
                        this.AccountModalLoading = false;
                        this.loading = false;
                        console.log(error);
                      });
                  } else {
                    this.verifyMessage();
                    this.loading = false;
                  }
                })
                .catch((error) => {
                  this.AccountModalLoading = false;
                  this.loading = false;
                  console.log(error);
                });
            } else {
              this.accountNumberNotFoundMessage();
            }
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //VERIFY POPUP OK
    verifyAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Verify").then((res) => {
        if (res.data.valid) {
          this.AccountModalLoading = true;
          store
            .dispatch("account/verifyAccount", {
              auth: this.user,
              accountData: this.accountData,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.successVerifiedMessage();

                if (res.data.insertAccount == 1) {
                  this.successInsertAccountMessage(
                    res.data.insertAccountMessage
                  );
                }
                this.AccountModalLoading = false;
                this.accountVerifyModal = false;
                this.getAllEntries();
              } else {
                this.AccountModalLoading = false;
                if(res.data.info == "error"){
                  this.errorMessage(this.$t("account_creation.messages.verify_account_error"));
                } else {
                  this.errorMessage(res.data.message);
                }
                
              }
            })
            .catch((error) => {
              this.AccountModalLoading = false;
              this.getAllEntries();

              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    accountNameNumberCheck(entry) {
      let checkAccountName = 0;
      let checkAccountNumber = 0;

      if (entry == "amend") {
        if (this.preAccount.AccountName != this.accountData.AccountName) {
          checkAccountName = 1;
        }

        if (this.preAccount.AccountNumber != this.accountData.AccountNumber) {
          checkAccountNumber = 1;
        }
      } else if (entry == "create") {
        if (this.accountData.AccountName != null) {
          checkAccountName = 1;
        }

        if (this.accountData.AccountNumber) {
          checkAccountNumber = 1;
        }
      }

      if (
        this.accountData.AccountName != null &&
        this.accountData.AccountName != undefined &&
        this.accountData.AccountName != ""
      ) {
        this.AccountModalLoading = true;
        store
          .dispatch("account/accountDetailsExist", {
            auth: this.user,
            accountName: this.accountData.AccountName,
            accountNumber: this.accountData.AccountNumber,
            checkAccountName: checkAccountName,
            checkAccountNumber: checkAccountNumber,
          })
          .then((res) => {
            this.AccountModalLoading = false;
            if (res.data.info == "exist_name") {
              this.accountData.AccountName = null;
              this.errorToastMessage(this.$t(
                    "account_creation.messages.account_number_exist_name"
                  ));
            } else if (res.data.info == "exist_number") {
              this.accountData.AccountNumber = null;
              this.errorToastMessage(this.$t(
                    "account_creation.messages.account_number_exist_number"
                  ));
            }
          })
          .catch((error) => {
            this.AccountModalLoading = false;

            console.log(error);
          });
      } else if (
        this.accountData.AccountNumber != null &&
        this.accountData.AccountNumber != undefined &&
        this.accountData.AccountNumber != ""
      ) {
        this.AccountModalLoading = true;
        store
          .dispatch("account/accountNumberExist", {
            auth: this.user,
            accountNumber: this.accountData.AccountNumber,
            checkAccountNumber: checkAccountNumber,
            entry: entry,
          })
          .then((res) => {
            this.AccountModalLoading = false;
            if (res.data.info == "exist_number") {
              this.accountData.AccountNumber = null;
              this.errorToastMessage(this.$t(
                    "account_creation.messages.account_number_exist_number"
                  ));
            }
          })
          .catch((error) => {
            this.AccountModalLoading = false;

            console.log(error);
          });
      }
    },

    accountNumberCheck(val) {
      if (
        this.accountData.AccountNumber != null &&
        this.accountData.AccountNumber != 0 &&
        this.accountData.AccountNumber != ""
      ) {
        this.accountData.AccountNumber = this.accountData.AccountNumber.replace(
          ".",
          ""
        );

        this.accountData.AccountNumber = this.accountData.AccountNumber.replace(
          "-",
          ""
        );

        if (this.accountData.AccountNumber.length > 10) {
          this.wrongToastMessage(
            this.$t("account_creation.messages.please_enter_less_than_digit")
          );
          this.accountData.AccountNumber = null;
        } else if (this.accountData.AccountNumber.length < 10) {
          for (let i = this.accountData.AccountNumber.length; i < 10; i++) {
            this.accountData.AccountNumber =
              "0" + this.accountData.AccountNumber;
          }

          this.accountNameNumberCheck("create");
        } else {
          this.accountNameNumberCheck("create");
        }
      }
    },
    checkPhoneNumber() {
      this.phoneNumberCheck = true;
      if (this.accountData.Phone1 != null && this.accountData.Phone2 != null) {
        if (this.accountData.Phone1 != this.accountData.Phone2) {
          this.phoneNumberCheck = false;
          this.phoneNumberMessage(
            this.$t("account_creation.messages.please_add_equal_phone_number")
          );
        }
      }
    },

    //****************CREATE START
    createAccountModal() {
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.accountData.AccountNumber = 0;
          this.allAccounts.forEach((element) => {
            if (
              Number(element.AccountNumber) >= this.accountData.AccountNumber
            ) {
              this.accountData.AccountNumber =
                Number(element.AccountNumber) + 1;
            }
          });

          if (String(this.accountData.AccountNumber).length < 10) {
            for (
              let i = String(this.accountData.AccountNumber).length;
              i < 10;
              i++
            ) {
              this.accountData.AccountNumber =
                "0" + String(this.accountData.AccountNumber);
            }
          }
          this.accountCreateModal = true;
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    createAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.createValidation.validate().then((success) => {
            if (success) {
              if (this.phoneNumberCheck) {
                this.AccountModalLoading = true;
                store
                  .dispatch("account/insertIntoCustomer", {
                    auth: this.user,
                    accountData: this.accountData,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                      this.accountCreateMessage();
                      this.AccountModalLoading = false;
                      this.accountCreateModal = false;
                      this.resetAccountModal();
                      this.getAllEntries();
                    } else {
                      this.errorMessage(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.AccountModalLoading = false;
                    this.accountCreateModal = false;
                    this.getAllEntries();

                    console.log(error);
                  });
              } else {
                this.phoneNumberMessage(
                  this.$t(
                    "account_creation.messages.please_add_equal_phone_number"
                  )
                );
              }
            } else {
              let notEnteredDatas = "";

              let checkRequiredDatas = _.map(
                this.$refs.createValidation.errors,
                (value, key) => ({ key, value })
              );

              if (checkRequiredDatas.length > 0) {
                let first = true;
                checkRequiredDatas.forEach((r) => {
                  if (r.value.length > 0) {
                    if (first) {
                      notEnteredDatas += r.key;
                      first = false;
                    } else {
                      notEnteredDatas += "," + r.key;
                      first = false;
                    }
                  }
                });
                this.checkRequiredInputsMessage(notEnteredDatas, 20000);
              }
            }
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************CREATE END

    //****************SEARCH START
    searchAccountModal() {
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          this.accountData.HolderType = "";
          this.accountData.SettlementType = "";
          this.accountData.TaxResidency = 'All';
          this.countryNames.unshift('All');
          this.accountSearchModal = true;
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    searchAccount(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          this.AccountModalLoading = true;
          this.loading = true;
          store
            .dispatch("account/selectFromCustomer", {
              auth: this.user,
              accountData: this.accountData,
            })
            .then((res) => {
              // console.log(res.data);
              this.allAccounts = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
              this.AccountModalLoading = false;
              this.resetAccountModal();
            })
            .catch((error) => {
              this.AccountModalLoading = false;
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************SEARCH END

    //****************VIEW START
    viewAccountModal(item) {
      this.$checkAccessRight("AccountCreation", "View").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            this.AccountModalLoading = true;
            this.loading = true;
            store
              .dispatch("account/selectAccNoFromCustomer", {
                auth: this.user,
                accountNumber: item.AccountNumber,
                ID:item.id
              })
              .then((res) => {
                this.accountData = {
                  AccountName: res.data.AccountName,
                  AccountNumber: res.data.AccountNumber,
                  HolderType: res.data.HolderType,
                  SettlementType: res.data.SettlementType,
                  TaxResidency: res.data.TaxResidency,
                  DisplayName: res.data.DisplayName,
                  Phone1: res.data.Phone1,
                  Phone2: res.data.Phone2,
                  ContactEmail: res.data.ContactEmail,
                  Email2: res.data.Email2,
                  Address: res.data.Address,
                  AmendedBy: res.data.AmendedBy,
                  AmendmentDate: res.data.AmendmentDate,
                  AmendmentReason: res.data.AmendmentReason,
                  AmendmentTime: res.data.AmendmentTime,
                  ClientNote: res.data.ClientNote,
                  CreatedBy: res.data.CreatedBy,
                  CreationDate: res.data.CreationDate,
                  CreationTime: res.data.CreationTime,
                  VerificationDate: res.data.VerificationDate,
                  VerificationTime: res.data.VerificationTime,
                  VerifiedBy: res.data.VerifiedBy,
                  History: res.data.History,
                };

                this.loading = false;
                this.AccountModalLoading = false;
                this.accountViewModal = true;
              })
              .catch((error) => {
                this.AccountModalLoading = false;
                this.loading = false;
                console.log(error);
              });
          } else {
            this.AccountIDDoesntZeroMessage();
            this.loading = false;
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },
    //****************VIEW END

    //****************createCodeSelectedAccount START
    createCodeSelectedAccount(item) {
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.VerifiedBy != null) {
              if (item.AccountNumber != null) {
                this.createCodeModalLoading = true;
                this.loading = true;
                store
                  .dispatch("account/createCodeSelectedAccount", {
                    auth: this.user,
                    accountNumber: item.AccountNumber,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                      this.createCodeAccountData = {
                        ID: item.id,
                        AccountName: item.AccountName,
                        AccountNumber: item.AccountNumber,
                        AccountCcy: res.data.customerInfo.HolderType,
                        AccountType: this.optAccountTypes[0],
                        AccountCode: res.data.customerInfo.AccountCode,
                        HolderType: "USD",
                        DisplayName: res.data.customerInfo.DisplayName,
                        Phone1: res.data.customerInfo.Phone1,
                        Phone2: res.data.customerInfo.Phone2,
                        ContactEmail: res.data.customerInfo.ContactEmail,
                        Email2: res.data.customerInfo.Email2,
                        Address: res.data.customerInfo.Address,
                        AmendedBy: res.data.customerInfo.AmendedBy,
                        AmendmentDate: res.data.customerInfo.AmendmentDate,
                        AmendmentReason: res.data.customerInfo.AmendmentReason,
                        AmendmentTime: res.data.customerInfo.AmendmentTime,
                        ClientNote: res.data.customerInfo.ClientNote,
                        CreatedBy: res.data.customerInfo.CreatedBy,
                        CreationDate: res.data.customerInfo.CreationDate,
                        CreationTime: res.data.customerInfo.CreationTime,
                        VerificationDate:
                          res.data.customerInfo.VerificationDate,
                        VerificationTime:
                          res.data.customerInfo.VerificationTime,
                        VerifiedBy: res.data.customerInfo.VerifiedBy,
                        History: res.data.customerInfo.History,
                      };

                      this.optAccountCcy = res.data.currencies;

                      this.createCodeAccountData.AccountCode =
                        this.createCodeAccountData.AccountType.slice(0, 4) +
                        item.AccountNumber;

                      this.loading = false;
                      this.createCodeModalLoading = false;
                      this.createCodeModal = true;
                    } else {
                      this.errorMessage(this.$t("account_creation.messages.not_found_customer"));
                      this.loading = false;
                    }
                  })
                  .catch((error) => {
                    this.createCodeModalLoading = false;
                    this.loading = false;
                    console.log(error);
                  });
              } else {
                this.warningMessage(
                  this.$t(
                    "account_creation.messages.entry_does_not_account_number"
                  )
                );
              }
            } else {
              this.warningMessage(
                this.$t("account_creation.messages.entry_does_not_verify_yet")
              );
            }
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //create code model OK
    createNewAccountCode(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("AccountCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.createCodeValidation.validate().then((success) => {
            if (success) {
              this.createCodeModalLoading = true;
              store
                .dispatch("account/insertIntoCodeAccount", {
                  auth: this.user,
                  codeAccountData: this.createCodeAccountData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.accountCodeCreateMessage();
                    this.createCodeModalLoading = false;
                    this.createCodeModal = false;
                    this.$router.push({
                      path: `/account-creation/selected-account-codes/${this.createCodeAccountData.ID}`,
                      query: {
                        accountName: this.createCodeAccountData.AccountName,
                      },
                    });
                    //this.getAllEntries();
                  } else {
                    if(res.data.info == 'wrong_number'){
                      this.errorMessage(this.$t("account_creation.messages.account_number_wrong_number"));
                    }else if(res.data.info == 'wrong_currency') {
                      this.errorMessage(this.$t("account_creation.messages.account_number_wrong_currency"));
                    } else if(res.data.info == 'wrong_code'){
                      this.errorMessage(this.$t("account_creation.messages.account_number_wrong_code"));
                    } else {
                      this.errorMessage(res.data.message);
                    }
                    this.createCodeModalLoading = false;
                  }
                })
                .catch((error) => {
                  this.createCodeModalLoading = false;
                  //this.createCodeModal = false;
                  //this.getAllEntries();
                  console.log(error);
                });
            } else {
              let notEnteredDatas = "";

              let checkRequiredDatas = _.map(
                this.$refs.createCodeValidation.errors,
                (value, key) => ({ key, value })
              );

              if (checkRequiredDatas.length > 0) {
                let first = true;
                checkRequiredDatas.forEach((r) => {
                  if (r.value.length > 0) {
                    if (first) {
                      notEnteredDatas += r.key;
                      first = false;
                    } else {
                      notEnteredDatas += "," + r.key;
                      first = false;
                    }
                  }
                });
                this.checkRequiredInputsMessage(notEnteredDatas, 20000);
              }
            }
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************createCodeSelectedAccount END
    bringCodesSelectedAccount(item) {
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            if (item.VerifiedBy != null) {
              if (item.AccountNumber != null) {
                this.$router.push({
                  path: `/account-creation/selected-account-codes/${item.id}`,
                  query: { accountName: item.AccountName },
                });
              } else {
                this.warningMessage(
                  this.$t(
                    "account_creation.messages.entry_does_not_account_number"
                  )
                );
              }
            } else {
              this.warningMessage(
                this.$t("account_creation.messages.entry_does_not_verify_yet")
              );
            }
          } else {
            this.AccountIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },
    bringAllAccountCodes() {
      this.$checkAccessRight("AccountCreation", "Search").then((res) => {
        if (res.data.valid) {
          this.$router.push({
            name: `allAccountCodes`,
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    resetAccountModal() {
      this.accountData = {
        AccountName: null,
        AccountNumber: null,
        HolderType: "INDIVIDUAL",
        SettlementType: "CUSTODIAN",
        TaxResidency:'United Kingdom',
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        AmendedBy: null,
        AmendmentDate: null,
        AmendmentReason: null,
        AmendmentTime: null,
        ClientNote: null,
        CreatedBy: null,
        CreationDate: null,
        CreationTime: null,
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
      };

      this.createCodeAccountData = {
        AccountName: null,
        AccountNumber: null,
        AccountCcy: "CURRENT",
        AccountType: null,
        AccountCode: null,
        HolderType: null,
        DisplayName: null,
        Phone1: null,
        Phone2: null,
        ContactEmail: null,
        Email2: null,
        Address: null,
        ID: null,
        ClientNote: null,
      };
      this.accountCreateModal = false;
      this.accountSearchModal = false;
      this.accountAmendModal = false;
      this.createCodeModal = false;
      this.preAccount = null;
      this.phoneNumberCheck = true;
      this.amendedReasonText = null;
      this.optAccountCcy = [];
      var index = this.countryNames.indexOf('All');
      if (index !== -1) {
        this.countryNames.splice(index, 1);
}
    },

    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const amendedRowColor = "table-amended";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      const sampleColor = "sampleColor";

      if (!item || type !== "row") {
        return;
      }

      // if (item.VerifiedBy != null) {
      //   return verifiedRowColor;
      // }
      if (item.AmendedBy != null) {
        return amendedRowColor;
      }
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("account_creation.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    amendOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.amend_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifyMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.entry_already_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    accountCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("account_creation.messages.account_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    accountCodeCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "account_creation.messages.account_code_create_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successVerifiedMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("account_creation.messages.account_verify_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successInsertAccountMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: message,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    wrongToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    phoneNumberMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    AccountIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.account_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verfiedAmendMessage(){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Info",
          text: this.$t("account_creation.messages.verified_account_amend"),
          icon: "InfoIcon",
          variant: "primary",
        },
      });
    },
    accountNumberNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.account_number_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("account_creation.messages.not_authorized"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_processes_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_processes_warning"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_copy_warning"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_copy_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("account_creation.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("account_creation.messages.you_not_change_account"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes("to")) {
            this.filterDateStart = this.rangeDate.split(" to ")[0];
            this.filterDateEnd = this.rangeDate.split(" to ")[1];

            this.selectedEvents();
          }
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          if (
            _.filter(val, {
              VerifiedBy: null,
              VerificationTime: null,
              VerificationDate: null,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Verify",
              title: this.$t("multiCheckBoxTitles.verify"),
              icon: "CheckSquareIcon",
              color: "green",
            });
          }
          
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style scoped>
#accountModal .modal-header .modal-title {
  color: white !important;
}
.padding_08 {
  padding: 0.7rem !important;
}
/* .table-verified {
  background-color: greenyellow !important;
} */

.table-amended {
  background-color: #ffff99 !important;
}

.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
  min-height: 38.59px !important;
}


.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-height: 38.59px !important;
}


.sticky-column { position: sticky; background-color: #fff; z-index: 1; }
.sticky-column:nth-child(1) { left: 0; }
.sticky-column:nth-child(2) { left: 50px; }
.other-col {  z-index: 0; }

.b-table-sticky-column:last-child {
  right: 0;
}

#table-container {

display: flex;

flex-wrap: nowrap;

overflow-x: auto;

}




#table-container::-webkit-scrollbar-track {

-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

}

.ID_background {
  background-color: gainsboro !important;
}

</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>
